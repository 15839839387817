/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'

const Map = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11636.600126478783!2d-2.4663912!3d43.1853627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb2cdc732b0e454a1!2sESTILOGR%C3%81FICO!5e0!3m2!1sen!2shk!4v1612696811299!5m2!1sen!2shk" width="100%"
    height="600"
    frameBorder="0"
    allowFullScreen=""
    aria-hidden="false"
    tabIndex="0">

  </iframe>

)

export default Map
